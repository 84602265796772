<template>
  <div>
    <div class="app-sidebar-content">
      <div class="sidebar-my-menu-edit-layer">
        <div class="sidebar-menu-all-title">편집 후 저장을 클릭하세요.</div>
        <div class="no-bookmark" v-if="bookmarks.length == 0">나만의 메뉴가 없습니다.</div>
        <draggable
          v-else
          tag="ul"
          :list="bookmarks"
          class="sidebar-my-ol"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          @change="moveMenu"
          :move="checkMove"
        >
          <li 
            class="sidebar-my-menu-item"
            v-for="(item, index) in bookmarks"
            :key="index"
            >
            <a class="shortcut_a" @click="goMobilePage(item.href)">
              <div class="sa_mw">
                <i :class="['material-icons sidebar-my-menu-icon', getIcons(item.icon, 'color')]">{{ getIcons(item.icon, 'icon') }}</i>
                <div class="sidebar-my-menu-title" v-html="item.title.replace(' ','<br/>') "></div>
                <i class="material-icons sidebar-my-menu-icon-edit">bookmark</i>
              </div>
            </a>
          </li>
        </draggable>
      </div>
    </div>
    <!-- 버튼 영역 -->
    <div class="save-bottom-box-btn">
      <q-btn-group outline >
        <c-btn label="저장" icon="save" @btnClicked="saveSetting" />
      </q-btn-group>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import mobileConfig from '@/js/mobileConfig';
import draggable from 'vuedraggable';
export default {
  components: {
    draggable,
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  data() {
    return {
      bookmarks: [],
      isOpen: false,
      sidebarActive: false,
      collapsed: true,
      windowWidth: 0,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '100%',
        isFull: true,
        param: {},
        closeCallback: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getBookmarks();
    },
    moveMenu() {
      let _cnt = 1;
      this.$_.forEach(this.bookmarks, item => {
        item.sortOrder = _cnt;
        item.userId = this.$store.getters.user.userId;
        _cnt++;
      })
    },
    checkMove: function(e) {
      if (e.draggedContext.futureIndex === 0) {
        return false;
      }
    },
    getBookmarks() {
      this.$http.url = mobileConfig.sys.favorite.mobile.list.url;
      this.$http.type = 'GET';
      this.$http.isLoading = false;
      this.$http.param = {
        userId: this.$store.getters.user.userId,
      };
      this.$http.request((_result) => {
        this.bookmarks = _result.data;
      },);
    },
    getIcons(_icons, _type) {
      if (_type == 'icon') {
        return _icons.split('|')[0]
      } else {
        return _icons.length > 1 ? 'text-' + _icons.split('|')[1] : 'text-grey-10';
      }
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    saveSetting() {
      this.$http.url = mobileConfig.sys.favorite.mobile.save.url;
      this.$http.type = 'POST';
      this.$http.param = this.bookmarks;
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      });
    }
  },
};
</script>
<style lang="scss">
.ghost {
  opacity: 0.2;
}
</style>
